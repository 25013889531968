<template>
    <div class="new_email_task_list">
        <el-card>
            <el-page-header @back="goBack" style="margin-bottom: 20px;"></el-page-header>
            <div class="flex">
                <el-form :model="form" label-width="120px" style="flex:1">
                    <el-form-item label="任务名称">
                        <el-col :span="8">
                            <el-input v-model="form.taskName"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="本周精选类型">
                        <el-select v-model="form.topType" placeholder="请选择" size="medium" @change="toChagne">
                            <el-option label="文章" :value="1" />
                            <el-option label="活动" :value="2" />
                            <el-option label="报告" :value="3" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="本周精选">
                        <el-col :span="8">
                            <el-input v-model="form.topTitle" disabled placeholder="根据类型选择：文章/活动/报告" />
                        </el-col>
                        <el-button type="primary" class="btnAdd" style="margin-left: 20px" @click="select"
                            >请选择</el-button
                        >
                    </el-form-item>
                    <el-form-item label="文章选择">
                        <div style="margin-bottom: 20px;">
                            <fuzzy-selection
                                ref="news1"
                                :title.sync="form.newsTitle1"
                                tipName="文章"
                                :type="9"
                                :value.sync="form.newsId1"
                            ></fuzzy-selection>
                        </div>
                        <div style="margin-bottom: 20px;">
                            <fuzzy-selection
                                ref="news2"
                                :title.sync="form.newsTitle2"
                                tipName="文章"
                                :type="9"
                                :value.sync="form.newsId2"
                            ></fuzzy-selection>
                        </div>
                        <div>
                            <fuzzy-selection
                                ref="news3"
                                :title.sync="form.newsTitle3"
                                tipName="文章"
                                :type="9"
                                :value.sync="form.newsId3"
                            ></fuzzy-selection>
                        </div>
                    </el-form-item>
                    <el-form-item label="合作选择">
                        <div style="margin-bottom: 20px;">
                            <fuzzy-selection
                                ref="cooperation1"
                                :title.sync="form.cooperationTitle1"
                                tipName="合作"
                                :type="10"
                                :value.sync="form.cooperationId1"
                            ></fuzzy-selection>
                        </div>
                        <div style="margin-bottom: 20px;">
                            <fuzzy-selection
                                ref="cooperation2"
                                :title.sync="form.cooperationTitle2"
                                tipName="合作"
                                :type="10"
                                :value.sync="form.cooperationId2"
                            ></fuzzy-selection>
                        </div>
                        <div>
                            <fuzzy-selection
                                ref="cooperation3"
                                :title.sync="form.cooperationTitle3"
                                tipName="合作"
                                :type="10"
                                :value.sync="form.cooperationId3"
                            ></fuzzy-selection>
                        </div>
                    </el-form-item>
                    <el-form-item label="报告选择">
                        <div style="margin-bottom: 20px;">
                            <fuzzy-selection
                                ref="reportTitle1"
                                :title.sync="form.reportTitle1"
                                tipName="报告"
                                :type="11"
                                :value.sync="form.reportId1"
                            ></fuzzy-selection>
                        </div>
                        <div style="margin-bottom: 20px;">
                            <fuzzy-selection
                                ref="reportTitle2"
                                :title.sync="form.reportTitle2"
                                tipName="报告"
                                :type="11"
                                :value.sync="form.reportId2"
                            ></fuzzy-selection>
                        </div>
                    </el-form-item>
                    <el-form-item label="发送时间">
                        <el-date-picker
                            v-model="form.sendTime"
                            type="datetime"
                            placeholder="选择日期"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            style="width: 300px"
                            size="medium"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="add">{{ "保存" }}</el-button>
                        <!-- <el-button size="small" @click="preview">{{ "预览" }}</el-button> -->
                    </el-form-item>
                </el-form>
                <div style="width:760px; height:80vh;" class="for">
                    <iframe :src="jsonUrl" style="width: 100%; height: 160vh;" frameborder="0"></iframe>
                </div>
            </div>
        </el-card>
        <pop ref="pop" @showEdit="showEdit"></pop>
        <el-dialog :visible.sync="show" width="750px"> </el-dialog>
    </div>
</template>

<script>
import pop from "./pop";
import fuzzySelection from "@/components/fuzzySelection";
export default {
    components: {
        pop,
        fuzzySelection,
    },
    data() {
        return {
            form: {
                taskName: "",
                topType: 1,
                topId: "",
                topTitle: "",
                newsTitle1: "",
                newsTitle2: "",
                newsTitle3: "",
                newsId1: "",
                newsId2: "",
                newsId3: "",
                cooperationTitle1: "",
                cooperationId1: "",
                cooperationTitle2: "",
                cooperationId2: "",
                cooperationTitle3: "",
                cooperationId3: "",
                reportTitle1: "",
                reportId1: "",
                reportTitle2: "",
                reportId2: "",
                sendTime: "",
            },
            show: false,
            id: 0,
        };
    },
    watch: {},
    created() {
        this.id = this.$route.query.id;
        this.getForm();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        toChagne() {
            this.form.topId = "";
            this.form.topTitle = "";
        },
        async getForm() {
            if (!this.id) return;
            let { data } = await this.$http.get("/admin/Email/getById", { params: { id: this.id } });
            this.form = JSON.parse(data.data.dataJson);
            // let dataJson =
            //     '{"taskName":"\\u626c\\u5e06\\u51fa\\u6d77\\u00b7\\u6bcf\\u5468\\u51fa\\u6d77\\u8d44\\u8baf\\u7cbe\\u9009","newsTitle1":"\\u5982\\u4f55\\u5728\\u4e9a\\u6d32\\u65b0\\u5174\\u5e02\\u573a\\u8d62\\u5f97\\u9876\\u5c16\\u4eba\\u624d\\u4e4b\\u6218","newsId1":13851,"newsTitle2":"\\u5012\\u8ba1\\u65f610\\u5929\\u4e28PAGC2024\\u5e7f\\u5dde\\u518d\\u805a\\u9996\\uff015\\u67089\\u65e5-5\\u670810\\u65e5\\u4e24\\u5929\\u7cbe\\u5f69\\u4e0d\\u505c \\u53ea\\u7b49\\u4f60\\u6765\\uff01","newsId2":13856,"newsTitle3":"\\u79d1\\u5c14\\u8482\\u74e6\\u516c\\u53f8\\u63a8\\u51fa\\u6b27\\u76df\\u53cd\\u68ee\\u6797\\u780d\\u4f10\\u6cd5\\u89c4\\u65b0\\u5de5\\u5177","newsId3":13852,"cooperationTitle1":"live casino \\u81ea\\u7814 \\u5bfb\\u53d1\\u884c \\u4e5f\\u53efAPI\\u5916\\u653e \\u591a\\u6b3e \\u82f1\\u6587\\u3002","cooperationId1":4763,"cooperationTitle2":"\\u5f81\\u6c42\\u5408\\u4f5c\\uff0c\\u6211\\u9700\\u8981\\u5df4\\u897f\\u5f53\\u5730\\u8d44\\u6e90\\uff0c\\u8ddf\\u4e9a\\u9a6c\\u900a\\\/\\u867e\\u76ae\\u5b98\\u65b9\\u5408\\u4f5c\\u7684\\u3002 \\u6211\\u4eec\\u76ee\\u524d\\u9700\\u6c42\\u4ee5\\u4e0b\\u8d44\\u6e90\\uff1a","cooperationId2":4754,"cooperationTitle3":"\\u8d8a\\u5357\\u6e38\\u620f\\u53d1\\u884c\\u5bfb\\u6e38\\u620f\\u5408\\u4f5c","cooperationId3":4762,"reportTitle1":"\\u6d77\\u5916\\u70ed\\u95e8\\u77ed\\u5267\\u5e94\\u7528\\u8d8b\\u52bf\\u6d1e\\u5bdf\\u62a5\\u544a","reportId1":981,"reportTitle2":"\\u957f\\u671f\\u3001\\u77ed\\u671f\\u548c\\u9057\\u6f0f\\u7684\\u4e2d\\u95f4\\u90e8\\u5206","reportId2":947,"sendTime":"2024-05-06 08:30:00"}';
            // console.log(JSON.parse(dataJson));
            // this.form = JSON.parse(dataJson);
            this.$nextTick(() => {
                this.$refs.news1.options = [
                    {
                        id: this.form.newsId1,
                        title: this.form.newsTitle1,
                    },
                ];
                this.$refs.news2.options = [
                    {
                        id: this.form.newsId2,
                        title: this.form.newsTitle2,
                    },
                ];

                this.$refs.news3.options = [
                    {
                        id: this.form.newsId3,
                        title: this.form.newsTitle3,
                    },
                ];
                this.$refs.cooperation1.options = [
                    {
                        id: this.form.cooperationId1,
                        title: this.form.cooperationTitle1,
                    },
                ];
                this.$refs.cooperation2.options = [
                    {
                        id: this.form.cooperationId2,
                        title: this.form.cooperationTitle2,
                    },
                ];
                this.$refs.cooperation3.options = [
                    {
                        id: this.form.cooperationId3,
                        title: this.form.cooperationTitle3,
                    },
                ];
                this.$refs.reportTitle1.options = [
                    {
                        id: this.form.reportId1,
                        title: this.form.reportTitle1,
                    },
                ];
                this.$refs.reportTitle2.options = [
                    {
                        id: this.form.reportId2,
                        title: this.form.reportTitle2,
                    },
                ];
            });
        },
        pleaseSelect() {},
        showEdit(row) {
            this.form.topId = row.id;
            this.form.topTitle = row.title;
        },
        async add() {
            var url = "/admin/Email/add ";
            if (this.id) {
                url = "/admin/Email/edit";
            }
            let { data: res } = await this.$http.post(url, { dataJson: JSON.stringify(this.form), id: this.id });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$router.push("/email_task_list");
            }
        },
        preview() {
            this.show = true;
        },
        select() {
            this.$refs.pop.init(this.form.topType, this.form.topId);
        },
    },
    computed: {
        jsonUrl() {
            let {
                newsTitle1,
                newsTitle2,
                newsTitle3,
                cooperationTitle1,
                cooperationTitle2,
                cooperationTitle3,
                reportTitle1,
                reportTitle2,
                ...req
            } = this.form;
            var jsod = JSON.stringify(req);
            return `https://www.yfchuhai.com/home/email/preview?dataJson=${jsod}`;
        },
    },
};
</script>

<style lang="less" scoped>
.for {
    transform: scale(0.5);
    transform-origin: 0 0;
}
</style>
