<template>
    <div>
        <el-dialog title="文章列表" :visible.sync="diokuai[1]" width="70%">
            <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
                <el-table-column prop="id" width="50" label="编号" />
                <el-table-column prop="title" label="标题" width="180">
                    <template slot-scope="ew">
                        <p>{{ ew.row.title }}</p>
                        <a v-if="ew.row.originLink" :href="ew.row.originLink" target="“_blank”" style="font-size: 12px">(原文链接)</a>
                    </template>
                </el-table-column>
                <el-table-column prop="categoryName" label="分类" width="180" />
                <el-table-column prop="createTime" label="发布时间" sortable width="180" />
                <el-table-column prop="author" label="作者" width="180" />
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="!id" type="text" icon="el-icon-info" size="mini" class="skyblue" @click="showEdit(scope.row)">添加</el-button>
                        <el-button v-if="id" type="text" icon="el-icon-info" size="mini" class="skyblue" @click="eadit(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                background
                layout="total, prev, pager, next"
                :current-page.sync="currentPage"
                :total="total"
                :page-size="pagesize"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-dialog>
        <el-dialog title="活动列表" :visible.sync="diokuai[2]" width="70%">
            <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass" :default-sort="{ prop: 'id', order: 'descending' }" @sort-change="sortChange">
                <el-table-column prop="id" width="50" label="编号" sortable="custom" />
                <el-table-column label="海报" width="180">
                    <template slot-scope="ew">
                        <img :src="baseurl + yu + ew.row.banner" alt="" style="width: 120px" />
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="分类" width="150">
                    <template slot-scope="ew">
                        {{ ew.row.categoryName }}
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="标题" width="150" />
                <el-table-column prop="regUrl" label="报名链接" width="180">
                    <template slot-scope="ew">
                        <a :href="ew.row.regUrl" target="“_blank”">{{ ew.row.regUrl }}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="endTime" label="活动时间" width="180">
                    <template slot-scope="scope"> {{ scope.row.beginTime }}至 {{ scope.row.endTime }} </template>
                </el-table-column>

                <el-table-column prop="beginTime" label="操作">
                    <template slot-scope="ew">
                        <el-button v-if="!id" type="text" icon="el-icon-info" size="mini" class="skyblue" @click="showEdit(ew.row)">添加</el-button>
                        <el-button v-if="id" type="text" icon="el-icon-info" size="mini" class="skyblue" @click="eadit(ew.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                background
                layout="total, prev, pager, next"
                :current-page.sync="currentPage"
                :total="total"
                :page-size="pagesize"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-dialog>
        <el-dialog title="报告列表" :visible.sync="diokuai[3]" width="70%">
            <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass" @sort-change="sortChange">
                <el-table-column prop="id" width="100" label="编号" sortable="custom" />
                <el-table-column prop="title" label="标题" width="180">
                    <template slot-scope="ew">
                        <p>{{ ew.row.title }}</p>
                        <p><a :href="baseurl + yu + ew.row.pdfUrl">(原文链接)</a></p>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="content" label="内容"> </el-table-column> -->
                <el-table-column prop="areaCode" label="地区">
                    <template slot-scope="scope">
                        {{ scope.row.areaCode | getarea }}
                    </template>
                </el-table-column>
                <el-table-column prop="businessId" label="行业">
                    <template slot-scope="scope">
                        {{ scope.row.businessId | getbu }}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="createTime" label="时间"> </el-table-column> -->
                <el-table-column prop="source" label="来源" />
                <el-table-column prop="banner" label="配图">
                    <template slot-scope="ew">
                        <img :src="baseurl + yu + ew.row.banner" alt="" style="width: 150px" />
                    </template>
                </el-table-column>
                <el-table-column prop="desc" label="描述" />
                <el-table-column prop="createTime" label="时间" />

                <el-table-column label="操作">
                    <template slot-scope="ew">
                        <el-button v-if="!id" type="text" icon="el-icon-info" size="mini" class="skyblue" @click="showEdit(ew.row)">添加</el-button>
                        <el-button v-if="id" type="text" icon="el-icon-info" size="mini" class="skyblue" @click="eadit(ew.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                background
                layout="total, prev, pager, next"
                :current-page.sync="currentPage"
                :total="total"
                :page-size="pagesize"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-dialog>
    </div>
</template>

<script>
var that;
export default {
    beforeCreate() {
        that = this;
    },
    data() {
        return {
            diokuai: {
                1: false,
                2: false,
                3: false,
            },
            list: [],
            id: "",
            currentPage: 1,
            total: 0,
            type: 1,
            area: [],
            pai: [],
            paiform: {},
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
        };
    },
    filters: {
        getarea: function(value) {
            if (that.area.reportArea) {
                var reportArea = that.area.reportArea[value];
                return reportArea;
            }
        },
        getbu(value) {
            if (that.area.reportBusiness) {
                var reportBusiness = that.area.reportBusiness[value];
                return reportBusiness;
            }
        },
        getviewType(val) {
            if (val == 1) {
                return "查看";
            } else {
                return "在线下载";
            }
        },
        getstau(val) {
            if (val == 1) {
                return "已发布";
            } else {
                return "未发布";
            }
        },
    },
    methods: {
        name() {},
        rowClass() {
            return "background:#F5F7FA;";
        },
        showEdit() {},
        handleCurrentChange(e) {
            this.currentPage = e;
            this.getlist();
        },
        handleSizeChange(e) {
            this.pagesize = e;
            console.log(e);
        },
        showEdit(row) {
            // this.formnew.sourceId = row.id;
            // this.formnew.orderNum = row.orderNum;
            // this.formnew.title = row.title;
            // if (row.picture) {
            //     this.formnew.logo = row.picture;
            // } else {
            //     this.formnew.logo = row.banner;
            // }
            this.$emit("showEdit", row);
            this.colse();
        },
        colse() {
            this.diokuai[this.type] = false;
        },
        eadit(row) {
            this.$emit("showEdit", row);
            this.colse();
        },
        async getlist() {
            if (this.type == 1) {
                this.$http
                    .get(`/admin/Article/getList`, {
                        params: {
                            page: this.currentPage,
                            pageSize: this.pagesize,
                            publicDate: "",
                            checkStatus: 1,
                            publishStatus: 1,
                            classification: "",
                            type: "",
                            sourceType: "",
                        },
                    })
                    .then(({ data: res }) => {
                        if (res.errorCode == 200) {
                            console.log(res);
                            this.list = res.data.list;
                            this.total = res.data.totalCount;
                            // this.getarea()
                            this.list.forEach((item) => {
                                this.pai.push({ id: item.id, pai: false });
                            });
                            this.getea();
                        }
                    });
            } else if (this.type == 2) {
                this.$http
                    .get(`/admin/Activity/getList`, {
                        params: {
                            page: this.currentPage,
                            pageSize: this.pagesize,
                            eventDate: "",
                            publiShdate: "",
                            title: "",
                            publishStatus: 1,
                            checkStatus: 1,
                            acId: "",
                            order: "",
                        },
                    })
                    .then(({ data: res }) => {
                        if (res.errorCode == 200) {
                            this.list = res.data.list;
                            this.total = res.data.totalCount;
                            this.list.forEach((item) => {
                                this.pai.push({ id: item.id, pai: false });
                            });
                        }
                    });
            } else {
                this.$http
                    .get("/admin/Report/getList", {
                        params: {
                            page: this.currentPage,
                            pageSize: this.pagesize,
                            date: "",
                            title: "",
                            year: "",
                            publishStatus: 1,
                            reportArea: "",
                            reportBusiness: "",
                            order: "",
                        },
                    })
                    .then(({ data: res }) => {
                        if (res.errorCode == 200) {
                            this.list = res.data.list;
                            this.total = res.data.totalCount;
                            this.list.forEach((item) => {
                                this.pai.push({ id: item.id, pai: false });
                            });
                            this.getea();
                        }
                    });
            }
        },
        getea() {
            this.$http.get("/admin/ReportCategory/getList").then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.area = res.data;
                }
            });
        },
        init(val, id) {
            this.type = val || 1;
            this.id = id || "";
            this.getlist();
            this.diokuai[this.type] = true;
        },
    },
};
</script>

<style lang="scss" scoped></style>
